<template>
  <div class="top_video" :style="isMobile ? '' : 'margin-top: -80px;'">
    <video width="100%"
      :src="videoUrl"
      :autoplay="autoplay"
      loop muted playsinline
      preload="metadata" oncontextmenu="return false;"
      style="background-image: url('/media/image/header.jpg')"
      onmouseover="this.play()" onmouseout="this.pause();" onclick="this.muted=!this.muted;">
    </video>
    <div class="jobs_go_div">
      <p :style="isMobile ? 'font-size: 20px' : 'font-size: 60px'">{{ isMobile ? $t('jobs_title').replace('. ', '\n') : $t('jobs_title') }}</p>
      <div class="jobs_go_button" :style="isMobile ? 'top: 100px;' : 'top: 150px;'"  @click="$router.push('/jobs_list')">
        <span :style="isMobile ? 'font-size: 15px;' : 'font-size: 30px;'">GO</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
  video {
		object-fit: fill;
	}
	.jobs_go_div {
		position: absolute;
    top: 50%;
		left: 50%;
		margin-right: -50%;
		transform: translate(-50%, -50%);
	}
  .jobs_go_div p {
		font-family: 'theboldfont';
    color: white;
    vertical-align: middle;
    text-align: center;
	}
	.jobs_go_button {
    cursor: pointer;
		position: absolute;
		left: 50%;
		margin-right: -50%;
		transform: translate(-50%, -50%);
		background: red;
		box-shadow: 4px 8px 40px rgba(34,90,89,0.5);
		border-radius: 20px;
		padding-left: 50px;
		padding-right: 50px;
		padding-top: 10px;
		padding-bottom: 10px;
	}
  .jobs_go_button span {
		font-family: 'theboldfont';
    color: white;
    vertical-align: middle;
	}
  .top_video {
    position: relative;
  }
</style>

<script>
export default {
  name: 'Jobs',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      videoUrl: '',
      autoplay: this.isMobile
    };
  }
};
</script>
